import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Header from './Components/Header/Header';
import Details from './Components/Details/Details';
import PassangerDetail from './Components/PassangerDetail/PassangerDetail';
import Sign from './Components/Sign/Sign';

function App() {
  return (
    <div className="App" >
       <Router>
       <Header />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/details" element={<Details />} />
        <Route path="/passengerDetail" element={<PassangerDetail />} />
        <Route path="/sign" element={<Sign />} />
        {/* Define other routes and components */}
      </Routes>
    </Router>
      {/* <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>

        </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
