import React, { useEffect, useRef, useState } from 'react'

import './Home.css'
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import city from '../Assets/Icons/city.svg'
import subcity from '../Assets/Icons/subcity.svg'
import { Link } from 'react-router-dom';
import { useStateValue } from '../../Store/Redux/stateProvider';
import rotating from '../Assets/Icons/arrows-rotate-solid.svg'
import angleup from '../Assets/Icons/angle-up-solid.svg'
import angleDown from '../Assets/Icons/angle-down-solid.svg'
import angleUp from '../Assets/Icons/angle-up-solid.svg'
import selected from '../Assets/Icons/selectedSeat.svg'




function Home() {
    const [originValue, setoriginValue] = useState('');
    const [dateList, setdateList] = useState([]);
    const [dateSelected, setdateSelected] = useState([]);
    const [selectedOrigin, setselectedOrigin] = useState('');
    const [isOpenOrigin, setisOpenOrigin] = useState(false);
    const [{ basket, user, tickets }, dispatch] = useStateValue();


    const handleOriginInputChange = (event, classname) => {
        const home__inputs = document.querySelector('.home__inputs');
        home__inputs.classList.add(classname)
        const value = event.target.value;
        setoriginValue(value);
        setselectedOrigin('');

        if (value) {
            setisOpenOrigin(true);
            setisOpendestination(false);
        } else {
            setisOpenOrigin(true);
            setisOpendestination(false);
        }
    };

    const handleOriginSelect = (option) => {
        setselectedOrigin(option);
        setoriginValue(option);
        setisOpenOrigin(false);
        resetPosition()

    };
    const [destinationValue, setdestinationValue] = useState('');
    const [selectedDestination, setselectedDestination] = useState('');
    const [isOpendestination, setisOpendestination] = useState(false);
    const [height, setheight] = useState('')
    const [searchResults, setSearchResults] = useState([1, 2, 3, 4])
    const [selectedSearchResult, setselectedSearchResult] = useState('')
    const [selectedDate, setselectedDate] = useState(dayjs())

    const handleDestinationInputChange = (event) => {
        const value = event.target.value;
        setdestinationValue(value);
        setselectedDestination('');

        if (value) {
            setisOpendestination(true);
            setisOpenOrigin(false);
        } else {
            setisOpendestination(true);
            setisOpenOrigin(false);
        }
    };

    const handleDestinationSelect = (option) => {
        setselectedDestination(option);
        setdestinationValue(option);
        setisOpendestination(false);
        resetPosition()
    };
    const inputListClose = (e) => {
        setisOpenOrigin(false);
        setisOpendestination(false);

    }
    const [departingDate, setdepartingDate] = React.useState(null);
    const [value, setValue] = React.useState(null);



    console.log(departingDate)
    console.log(selectedDate)
    const handleDepartingDate = (event) => {
        const value = event.target.value;
        setdepartingDate(value);
    };



    const handleDateChange = (newValue) => {
        const date = dayjs(newValue).format('YYYY-MM-DD');
        if (dateConvert(dayjs()) <= date) {
            setselectedDate(newValue)
            setdepartingDate(newValue);

        }
        if (date) {
            setdepartingDate(date);
        }
        ; // Extract the date using dayjs
        console.log(date); // Log the extracted date to the console
    };


    const tripLocations = [{ 'addis': ['Boru', 'Kaliti', 'Kolfe', 'Goro'] }, { 'add': ['Bole', 'Kaliti', 'Kolfe', 'Goro'] }, { 'Wolkite': ['Gubre', 'Emdebr', 'Agena'] }, 'Jimma', 'Asosa', 'Dire'];
    const [numTickets, setNumTickets] = useState(1);

    const handleIncrement = () => {

        dispatch({
            type: "SET_TICKETS",
            tickets: tickets + 1,
        });
    };

    const handleDecrement = () => {
        if (tickets > 0) {

            dispatch({
                type: "SET_TICKETS",
                tickets: tickets - 1,
            });
        }
    };
    const today = dayjs()
    const tomorrow = dayjs().add(1, 'day');
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    console.log(departingDate && departingDate)
    // console.log(selectedDate && selectedDate.format('YYYY-MM-DD'))


    const inputRef = useRef(null);

    useEffect(() => {
        const home__inputs = document.querySelector('.home__inputs');
        home__inputs && home__inputs.classList.remove("focusedDestination")
        home__inputs && home__inputs.classList.remove("focusedOrigin")
    }, [])
    const resetPosition = () => {
        const home__inputs = document.querySelector('.home__inputs');
        home__inputs && home__inputs.classList.remove("focusedDestination")
        home__inputs && home__inputs.classList.remove("focusedOrigin")
    }


    const handleFocus = (classname, openorigin, opendestination) => {
        setisOpenOrigin(openorigin)
        setisOpendestination(opendestination)
        const home__inputs = document.querySelector('.home__inputs');
        // const path__icon = document.querySelector('.path__icon');
        // path__icon.classList.add('d-none')

        home__inputs.classList.add(classname)
        // if (inputElement) {
        //     inputElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        //     window.scrollTo(100, -100);
        // }


        // if (window.innerWidth < 768) {
        //     e.scrollIntoView({ behavior: 'smooth', block: 'start' });
        //     window.scrollTo(200, 400);
        // } else {
        //     console.log("first")
        // }



    };
    const ticketsdisplay = () => {
        const ticket_counters = document.querySelector('.ticket_counters')
        ticket_counters.classList.toggle('d-none')
    }



    useEffect(() => {
        const handleScroll = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            setheight(vh)
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [height])
    // const handleScroll = () => {
    //     const vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    //     setheight(vh)
    // };
    // window.addEventListener('scroll', handleScroll);


    const searchResult = () => {
        const search__results = document.querySelector('.search__results')
        search__results.classList.remove('d-none')
        const vh = window.innerHeight;
        if (window.innerWidth < 768) {
            search__results.scrollIntoView({ behavior: 'smooth', block: 'start' });
            window.scrollTo(0, vh - 80);
        } else {
            search__results.scrollIntoView({ behavior: 'smooth', block: 'start' });
            window.scrollTo(0, vh - 50);
        }
    }


    useEffect(() => {
        if (dateConvert(selectedDate) > dateConvert(dayjs())) {
            const array = [-1, 0, 1, 2, 3, 4, 5]
            const dates = []
            for (let i = 0; i < array.length; i++) {
                selectedDate && dates.push(selectedDate.add(array[i], 'day'))
            }
            setdateList(dates)
        } else if (dateConvert(selectedDate) == dateConvert(dayjs())) {
            const array = [0, 1, 2, 3, 4, 5, 6]
            const dates = []
            for (let i = 0; i < array.length; i++) {
                selectedDate && dates.push(selectedDate.add(array[i], 'day'))
            }
            setdateList(dates)
        } else {

        }

    }, [departingDate, selectedDate])

    console.log(selectedSearchResult)

    const dateConvert = (day) => {
        return (day.format('YYYY-MM-DD'))
    }

    const toggleView = (index) => {
        if (selectedSearchResult === '') {
            setselectedSearchResult(index)

        } else if (selectedSearchResult !== '' && selectedSearchResult != index) {

            setselectedSearchResult(index)
        } else {
            setselectedSearchResult('')
        }

    }
    console.log(window.innerWidth)
    return (
        <div className='home d-flex align-items-center justify-content-center flex-column'>
            <div className='background__image'></div>

            <div className='home__top d-flex align-items-center justify-content-between flex-column justify-content-md-center'>

                <div className='top__titles'>
                    <div className='home__title d-none d-md-block text-center'>Zemen Bus</div>
                    <div className='home__subttitle text-center'>Select your travel destination</div>
                </div>
                <div className='home__inputs d-md-flex '>
                    <div className='origin '>
                        <div className='input d-flex flex-column Justify-content-center'>
                            {originValue !== '' ? <div className='input__label'>From</div> : ''}

                            <input

                                type="text"
                                value={originValue}
                                onChange={(event) => handleOriginInputChange(event, 'focusedOrigin')}
                                placeholder="From"
                                onFocus={() => handleFocus('focusedOrigin', true, false)}

                            />
                        </div>
                        {isOpenOrigin && originValue === '' && (
                            <ul className='locations__list  text-left ps-2'>
                                {tripLocations.map((location, index) => {
                                    if (typeof (location) == 'object') {
                                        let parents = Object.keys(location)[0]

                                        return <div ><li className='main_location ' key={index}>
                                            <img className='icon__image' src={city} alt="Logo" />{parents}</li>
                                            {location[parents].map((subloc) => {
                                                return <ul className=' text-left ps-2'><li className='sub_location bg-light ' key={index} onClick={() => handleOriginSelect(subloc)}><img className='icon__image' src={subcity} alt="icon" />{subloc}</li></ul>
                                            })}
                                        </div>
                                    } else {
                                        return <li className='main_location ' key={index} onClick={() => handleOriginSelect(location)}><img className='icon__image' src={city} alt="city icon" />{location}</li>
                                    }
                                }
                                )}
                            </ul>
                        )}
                        {isOpenOrigin && originValue !== '' && (
                            <ul className='locations__list'>
                                {tripLocations.map((location, index) => {
                                    if (typeof (location) == 'object') {
                                        let parents = Object.keys(location)[0]
                                        let childs = []
                                        location[parents].map((subloc) => { if (subloc.toLowerCase().includes(originValue.toLowerCase())) { childs.push(subloc) } })
                                        const childss = (location) => {
                                            let store = []
                                            location[parents].map((subloc) => { if (subloc.toLowerCase().includes(originValue.toLowerCase())) { store.push(subloc) } })
                                            return store
                                        }
                                        { location[parents].map((subloc) => { subloc.toLowerCase().includes(originValue.toLowerCase()) }) }
                                        console.log(parents.toLowerCase().includes(originValue.toLowerCase()))
                                        console.log(childs)

                                        if (parents.toLowerCase().includes(originValue.toLowerCase())) {
                                            return <div><li className='main_location' key={index} onClick={() => handleOriginSelect(parents)}>
                                                <img className='icon__image' src={city} alt="Logo" />{parents}</li>
                                                {location[parents].map((subloc) => {
                                                    return <ul><li className='sub_location' key={index} onClick={() => handleOriginSelect(subloc)}><img className='icon__image' src={subcity} alt="icon" />{subloc}</li></ul>
                                                })}</div>
                                        }
                                        else if (childss(location).length != 0) {
                                            console.log(parents)
                                            return <div><li className='main_location  ' key={index} onClick={() => handleOriginSelect(parents)}>
                                                <img className='icon__image' src={city} alt="Logo" />{parents}</li>
                                                {location[parents].map((subloc) => {
                                                    if (subloc.toLowerCase().includes(originValue.toLowerCase())) {
                                                        return <ul><li className='sub_location  ' key={index} onClick={() => handleOriginSelect(subloc)}><img className='icon__image' src={subcity} alt="icon" />{subloc}</li></ul>
                                                    }

                                                })}</div>
                                        }
                                    } else {
                                        if (location.toLowerCase().includes(originValue.toLowerCase())) {
                                            return <li key={index} onClick={() => handleOriginSelect(location)}>{location}</li>
                                        }

                                    }
                                }
                                )}
                            </ul>
                        )}

                    </div>

                    <div className='path__icon'>
                        <img className='path__icon__inner' src={rotating} />
                        {/* <div className='path__icon__inner'><img src={rotating}/></div> */}
                    </div>

                    <div className='destination'>
                        <div className='input d-flex flex-column Justify-content-center'>
                            {destinationValue !== '' ? <div className='input__label'>To</div> : ''}
                            <input
                                type="text"
                                value={destinationValue}
                                onChange={(event) => handleDestinationInputChange(event, 'focusedDestination')}
                                placeholder="To"
                                onFocus={() => handleFocus('focusedDestination', false, true)}

                            />
                        </div>
                        {isOpendestination && destinationValue === '' && (
                            <ul className='locations__list  text-left ps-2 '>
                                {tripLocations.map((location, index) => {
                                    if (typeof (location) == 'object') {
                                        let parents = Object.keys(location)[0]

                                        return <div ><li className='main_location  ' key={index}>
                                            <img className='icon__image' src={city} alt="Logo" />{parents}</li>
                                            {location[parents].map((subloc) => {
                                                return <ul className=' text-left ps-2'><li className='sub_location bg-light ' key={index} onClick={() => handleDestinationSelect(subloc)}><img className='icon__image' src={subcity} alt="icon" />{subloc}</li></ul>
                                            })}
                                        </div>
                                    } else {
                                        return <li className='main_location ' key={index} onClick={() => handleDestinationSelect(location)}><img className='icon__image' src={city} alt="city icon" />{location}</li>
                                    }
                                }
                                )}
                            </ul>
                        )}
                        {isOpendestination && destinationValue !== '' && (
                            <ul className='locations__list '>
                                {tripLocations.map((location, index) => {
                                    if (typeof (location) == 'object') {
                                        let parents = Object.keys(location)[0]
                                        let childs = []
                                        location[parents].map((subloc) => { if (subloc.toLowerCase().includes(destinationValue.toLowerCase())) { childs.push(subloc) } })
                                        const childss = (location) => {
                                            let store = []
                                            location[parents].map((subloc) => { if (subloc.toLowerCase().includes(destinationValue.toLowerCase())) { store.push(subloc) } })
                                            console.log(store)
                                            return store
                                        }
                                        { location[parents].map((subloc) => { subloc.toLowerCase().includes(destinationValue.toLowerCase()) }) }
                                        console.log(parents.toLowerCase().includes(destinationValue.toLowerCase()))
                                        console.log(childs)

                                        if (parents.toLowerCase().includes(destinationValue.toLowerCase())) {
                                            return <div><li className='main_location' key={index} onClick={() => handleDestinationSelect(parents)}>
                                                <img className='icon__image' src={city} alt="Logo" />{parents}</li>
                                                {location[parents].map((subloc) => {
                                                    return <ul><li className='sub_location  ' key={index} onClick={() => handleDestinationSelect(subloc)}><img className='icon__image' src={subcity} alt="icon" />{subloc}</li></ul>
                                                })}</div>
                                        }
                                        else if (childss(location).length != 0) {
                                            console.log(parents)
                                            return <div><li className='main_location  ' key={index} onClick={() => handleDestinationSelect(parents)}>
                                                <img className='icon__image' src={city} alt="Logo" />{parents}</li>
                                                {location[parents].map((subloc) => {
                                                    if (subloc.toLowerCase().includes(destinationValue.toLowerCase())) {
                                                        return <ul><li className='sub_location  ' key={index} onClick={() => handleDestinationSelect(subloc)}><img className='icon__image' src={subcity} alt="icon" />{subloc}</li></ul>
                                                    }

                                                })}</div>
                                        }
                                    } else {
                                        if (location.toLowerCase().includes(destinationValue.toLowerCase())) {
                                            return <li className='main_location  ' key={index} onClick={() => handleDestinationSelect(location)}>{location}</li>
                                        }

                                    }
                                }
                                )}
                            </ul>
                        )}

                    </div>
                    <div className='departing_date'>

                        <div className='input d-flex flex-column Justify-content-center '>
                            <div className='input__label'>Departing Date</div>
                            <LocalizationProvider dateAdapter={AdapterDayjs} className='bg-warning'>

                                {/* <DemoContainer
        components={['DatePicker', 'DateTimePicker', 'DateRangePicker']}
      > */}
                                <DatePicker onChange={handleDateChange} className=' date__picker__input'
                                    defaultValue={today}
                                    value={selectedDate}
                                    minDate={tomorrow}
                                    views={['year', 'month', 'day']} />
                                {/* </DemoContainer> */}
                            </LocalizationProvider>

                        </div>


                        <div className='input d-flex flex-column Justify-content-center d-none'>
                            {originValue !== '' ? <div className='input__label'>From</div> : ''}
                            <input
                                type="date"
                                value={departingDate}
                                onChange={handleDepartingDate}
                                placeholder="Type here..."
                                onFocus={() => {
                                    setisOpendestination(false)
                                    setisOpenOrigin(false);
                                }}
                            />
                        </div>
                    </div>
                    <div className='tickets'>
                        <div className='input ' onClick={() => ticketsdisplay()}>
                            <div className='input__label'>Passengers</div>
                            <div><span className='tickets__default px-3 fw-bold fs-4'>{tickets}</span></div>

                        </div>
                        <div className='ticket_counters flex-column d-none'>
                            <div >
                                <button onClick={handleDecrement}>-</button>
                                <span className='px-3 fw-bold fs-4'>{tickets}</span>
                                <button onClick={handleIncrement}>+</button>
                            </div>
                            <button className='counter__close__btn' onClick={() => ticketsdisplay()}>Close</button>
                        </div>
                    </div>
                    <div className='search_trips '>
                        <div className='input p-0'>
                            <button className='search_trips_btn' onClick={() => searchResult()}>Search</button>
                        </div>
                    </div>

                </div>

            </div>

            <div className='search__results py-2 w-100 d-none '>

                <div className='search__results__dates    ' >
                    <div className='search__results__dates__btns d-flex align-items-end justify-content-md-center'>

                        {dateList && dateList.map((date) => {
                            return <div className='search__results__dates__btn mt-5 ' key={date}>
                                {(dateConvert(dayjs()) == dateConvert(date)) ? <div className='search__results__dates__caption d-flex'>Today</div> : ""}
                                {dateConvert((dayjs().add(1, 'day'))) == dateConvert(date) ? <div className='search__results__dates__caption d-flex'>Tommorow</div> : ""}
                                <button onClick={() => handleDateChange(date)} className={dateConvert(selectedDate) === dateConvert(date) ? 'bg-warning' : ''}><div className='caption_date '>{weekDays[date.day()]} / {dateConvert(date)}</div>
                                </button>
                            </div>


                        })}




                    </div>
                </div>


                <div className='search__results__single__day py-3'>
                    {/* <div className='single__day__date'>Journey Date :{dateConvert(selectedDate)}</div> */}
                    {searchResults && searchResults.map((result, index) => {
                        return <div className='search__single container my-2 '>
                            <button className='search__single__top' onClick={() => { toggleView(index) }}>
                                <div className='search__single__top__left'>
                                    <div className='search__single__top__hour'><p>5:00 AM - 4:00 PM </p><p>(11h 0min)</p></div>
                                    <div className='search__single__top__route py-3'><p>Addis Abeba Mesqel Square - Bahir Dar Bus Station (direct)</p></div>
                                    <div className='search__single__top__ticket'><p>1 x Adult, Zemen Bus</p> Ethiopia</div>
                                </div>
                                <div className='search__single__top__right' >
                                    <div className='search__single__top__price d-flex'>Birr 400 <img src={selectedSearchResult === index ? angleUp : angleDown}></img></div>

                                </div>
                            </button>
                            {selectedSearchResult === index ||window.innerWidth>768 ?
                                <div className='search__single__bottom'>
                                    <div className='search__single__bottom__title d-md-none'>Trip Board</div>
                                    <div className='search__single__bottom__content'>
                                        <div className='search__single__bottom__left'>
                                            <div className='search__single__departure'>
                                                <div className='search__single__departure__time pe-md-1'>5:00 AM</div>
                                                <div className='search__single__departure__date d-md-none'>08/22</div>
                                            </div>
                                            <div className='search__single__departure'>
                                                <div className='search__single__length d-none d-md-block px-md-2'>12 hrs</div>

                                            </div>
                                            <div className='search__single__arrival'>
                                                <p className='search__single__arrival__time d-inline ps-md-1'>4:00 PM</p>
                                                <p className='search__single__arrival__date d-md-none'>08/22</p>
                                            </div>
                                            <div className='search__single__price d-none d-md-block'>400 birr</div>
                                        </div>
                                        <div className='search__single__bottom__divider'>


                                        </div>

                                        <div className='search__single__bottom__right'>
                                            <div className='search__single__from'>
                                                <div className='search__single__from__location'> <div className='search__single__bottom__pointer top-pointer'></div>Addis Ababa (addis ababa meskel square)</div>
                                                <div className='search__single__trip__number d-md-none'>travel number 111 (zemen bus ethiopia)</div>

                                            </div>
                                            <div></div>
                                            <div className='search__single__to d-flex ps-md-1'><div className='search__single__bottom__pointer bottom-pointer'></div>bahir dar (bahir dar bus station)</div>

                                        </div>


                                    </div>
                                    <div className='search__continue'>
                                        {/* <button className='search__continue__btn'>select and continue</button> */}
                                <Link className="search__continue__btn text-unstyled" to="/details">select and continue</Link>

                                    </div>
                                </div>
                                : null}
                           
                        </div>
                    })}
                </div>








            </div>
        </div>


    )
}

export default Home