import React, { useState } from 'react'
import { MdReportGmailerrorred } from 'react-icons/md'
import './ForgotPassword.css'
// import { auth } from '../../firebase'
import ModalContent from '../Modal/Modal'
import { Navigate } from 'react-router-dom'

function ForgotPassword() {
    const [isShow, invokeModal] = useState(false)
    const [email, setemail] = useState("")
    
        const initModal = () => {
            
          return invokeModal(!false)
        }
const closeModal=()=>{
    window.location.reload ()
    // return invokeModal(false)
  }
const reset=()=>{
    
    // auth.sendPasswordResetEmail(email).then(()=>{
        
    //     closeModal()
    //     alert("check your email")
    // }).catch((error)=>{
    //     alert(error)

    // })
}
  return (
    <div className='forgot d-flex align-items-center justify-content-center'>
       

        <ModalContent
        content={
            <div className='forgot_modal d-flex flex-column '>
            
            
            <div className='forgot_modal_emailtitle text-center p-3'>Enter your email and click the button below</div>
            <input className='w-75 px-3  mx-auto' type="email" autoFocus  onChange={(e)=>setemail(e.target.value)} ></input>
            <button className="forot_modal_reset_btn"onClick={reset}>Reset Password</button>
            </div>
        }

        button_text={"Forgot password?"}/>
    </div>
    
  )
}

export default ForgotPassword



