import React, { useState } from 'react'
import logo from '../Assets/Images/Zemen Logo.png'
import toggler from '../Assets/Icons/toggler_icon-01.png'
import './Header.css'
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar";
import { Link } from 'react-router-dom';



function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    const toggle = (e) => {
        alert(e.target.parentElement.parentElement.parentElement.children[2].innerHTML)
    }
    return (
        <div className='header sticky-top' >
            <nav className="container navbar navbar-expand-sm navbar-light p-0">
                <div className="container-fluid">
                    <Link className="navbar-brand " to="/"><img className='logo__image' src={logo} alt="Logo" /></Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleNav}
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}>
                        <ul className="navbar-nav w-100 justify-content-end" onClick={toggleNav}>
                            <li className="nav-item d-flex justify-content-end">
                                <Link className="nav-link py-1" to="/">Home</Link>
                            </li>
                            <li className="nav-item d-flex justify-content-end">
                                <Link className="nav-link py-1" to="/sign">Sign In</Link>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </nav>

        </div>
    )
}

export default Header

{/* <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>

        <li className="nav-item">
          <a className="nav-link" href="#">Link</a>
        </li>
        
        <li className="nav-item">
          <a className="nav-link disabled" aria-disabled="true">Disabled</a>
        </li> */}