import React, { useEffect, useState } from 'react'
import './Details.css'
import Table from 'react-bootstrap/Table';
import reserved from '../Assets/Icons/Reserved.svg'
import open from '../Assets/Icons/openSeat.svg'
import selected from '../Assets/Icons/selectedSeat.svg'
import steer from '../Assets/Icons/sterringWheel.png'
import Swal from 'sweetalert2'
import { useStateValue } from '../../Store/Redux/stateProvider';
import { Link } from 'react-router-dom';


const selectedImage = {
    backgroundImage: `url(${selected})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'white'
};
const reservedImage = {
    backgroundImage: `url(${reserved})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'white'
};
const openImage = {
    backgroundImage: `url(${open})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'black'
};


function Details() {
    const [selectedSeat, setSelectedSeat] = useState([])
    const [reservedSeat, setreservedSeat] = useState([1, 5, 2, 3, 48])


    // const [tickets, settickets] = useState(2)
    const [{ basket, user, tickets }, dispatch] = useStateValue();

    const appendSeat2 = (val, tickets) => {
        if (val <= 49 - tickets) {
            const arr = []
            for (let i = 1; i <= tickets; i++) {
                arr.push(val + i);
            }
            setSelectedSeat(arr)
        } else {
            reservedSeat.sort((a, b) => a - b);
            const available = findskippedSeats(reservedSeat)
            const selectedValues = available.slice(0, tickets);
            setSelectedSeat(selectedValues)
        }


    }
    function findskippedSeats(arr) {
        const skippedSeats = [];

        for (let i = 0; i < arr.length - 1; i++) {
            const currentNumber = arr[i];
            const nextNumber = arr[i + 1];

            if (nextNumber - currentNumber > 1) {
                const missingNumbers = Array.from({ length: nextNumber - currentNumber - 1 }, (_, index) => currentNumber + index + 1);
                skippedSeats.push(...missingNumbers);
            }
        }

        return skippedSeats;
    }

    useEffect(() => {
        appendSeat2(Math.max(...reservedSeat), tickets)
        window.scrollTo(0, 0);
    }, [])
    const appendSeat = (val) => {
        if (reservedSeat.includes(val)) {
            // alert('sorry this seat is taken')
            Swal.fire({
                text: 'Sorry This Seat Is Taken',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        } else {
            if (selectedSeat.length >= tickets && selectedSeat.includes(val)) {

                setSelectedSeat(prevSelectedSeat => prevSelectedSeat.filter(seat => seat !== val));
            } else if (selectedSeat.length >= tickets) {
                Swal.fire({
                    text: `Sorry, you can only select ${tickets} seats, if you want to select more seats go back and change number of tickets, if you want to change seat deselect selected seat first`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            else {
                if (selectedSeat.includes(val)) {
                    setSelectedSeat(prevSelectedSeat => prevSelectedSeat.filter(seat => seat !== val));
                } else {
                    setSelectedSeat(prevSelectedSeat => [...prevSelectedSeat, val]);
                }
            }
        }




    }
    console.log(selectedSeat)
    const imageSelect = (val) => {
        if (reservedSeat.includes(val)) {
            return reservedImage
        } else if (selectedSeat.includes(val)) {
            return selectedImage
        } else {
            return openImage
        }
    }
    return (
        <div className='details py-3 d-flex justify-content-center'>
            <div className='details__seat__Layout p-3 row g-0  container'>
                <div className='details__seat__Layout__whole d-flex justify-content-center justify-content-md-start col-md-6'>
                    <div className='bus'>
                        <div className='bus__row'>
                            <button className='single__seat steer' style={{
                                backgroundImage: `url(${steer})`, backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}></button>
                            <button className='single__seat' ></button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(4)} className='single__seat' onClick={() => appendSeat(4)}>4</button>
                            <button style={imageSelect(3)} className='single__seat' onClick={() => appendSeat(3)}>3</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(1)} className='single__seat' onClick={() => appendSeat(1)}>1</button>
                            <button style={imageSelect(2)} className='single__seat' onClick={() => appendSeat(2)}>2</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(8)} className='single__seat' onClick={() => appendSeat(8)}>8</button>
                            <button style={imageSelect(7)} className='single__seat' onClick={() => appendSeat(7)}>7</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(5)} className='single__seat' onClick={() => appendSeat(5)}>5</button>
                            <button style={imageSelect(6)} className='single__seat' onClick={() => appendSeat(6)}>6</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(12)} className='single__seat' onClick={() => appendSeat(12)}>12</button>
                            <button style={imageSelect(11)} className='single__seat' onClick={() => appendSeat(11)}>11</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(9)} className='single__seat' onClick={() => appendSeat(9)}>9</button>
                            <button style={imageSelect(10)} className='single__seat' onClick={() => appendSeat(10)}>10</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(16)} className='single__seat' onClick={() => appendSeat(16)}>16</button>
                            <button style={imageSelect(15)} className='single__seat' onClick={() => appendSeat(15)}>15</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(13)} className='single__seat' onClick={() => appendSeat(13)}>13</button>
                            <button style={imageSelect(14)} className='single__seat' onClick={() => appendSeat(14)}>14</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(20)} className='single__seat' onClick={() => appendSeat(20)}>20</button>
                            <button style={imageSelect(19)} className='single__seat' onClick={() => appendSeat(19)}>19</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(17)} className='single__seat' onClick={() => appendSeat(17)}>17</button>
                            <button style={imageSelect(18)} className='single__seat' onClick={() => appendSeat(18)}>18</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(24)} className='single__seat' onClick={() => appendSeat(24)}>24</button>
                            <button style={imageSelect(23)} className='single__seat' onClick={() => appendSeat(23)}>23</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(21)} className='single__seat' onClick={() => appendSeat(21)}>21</button>
                            <button style={imageSelect(22)} className='single__seat' onClick={() => appendSeat(22)}>22</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(28)} className='single__seat' onClick={() => appendSeat(28)}>28</button>
                            <button style={imageSelect(27)} className='single__seat' onClick={() => appendSeat(27)}>27</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(25)} className='single__seat' onClick={() => appendSeat(25)}>25</button>
                            <button style={imageSelect(26)} className='single__seat' onClick={() => appendSeat(26)}>26</button>
                            <button className='single__seat'></button>
                            <button className='single__seat'></button>
                            <button className='single__seat'></button>

                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(29)} className='single__seat' onClick={() => appendSeat(29)}>29</button>
                            <button style={imageSelect(30)} className='single__seat' onClick={() => appendSeat(30)}>30</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(32)} className='single__seat' onClick={() => appendSeat(32)}>32</button>
                            <button style={imageSelect(31)} className='single__seat' onClick={() => appendSeat(31)}>31</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(33)} className='single__seat' onClick={() => appendSeat(33)}>33</button>
                            <button style={imageSelect(34)} className='single__seat' onClick={() => appendSeat(34)}>34</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(36)} className='single__seat' onClick={() => appendSeat(36)}>36</button>
                            <button style={imageSelect(35)} className='single__seat' onClick={() => appendSeat(35)}>35</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(37)} className='single__seat' onClick={() => appendSeat(37)}>37</button>
                            <button style={imageSelect(38)} className='single__seat' onClick={() => appendSeat(38)}>38</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(40)} className='single__seat' onClick={() => appendSeat(40)}>40</button>
                            <button style={imageSelect(39)} className='single__seat' onClick={() => appendSeat(39)}>39</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(41)} className='single__seat' onClick={() => appendSeat(41)}>41</button>
                            <button style={imageSelect(42)} className='single__seat' onClick={() => appendSeat(42)}>42</button>
                            <button className='single__seat'></button>
                            <button style={imageSelect(44)} className='single__seat' onClick={() => appendSeat(44)}>44</button>
                            <button style={imageSelect(43)} className='single__seat' onClick={() => appendSeat(43)}>43</button>
                        </div>
                        <div className='bus__row'>
                            <button style={imageSelect(45)} className='single__seat' onClick={() => appendSeat(45)}>45</button>
                            <button style={imageSelect(46)} className='single__seat' onClick={() => appendSeat(46)}>46</button>
                            <button style={imageSelect(49)} className='single__seat' onClick={() => appendSeat(49)}>49</button>
                            <button style={imageSelect(48)} className='single__seat' onClick={() => appendSeat(48)}>48</button>
                            <button style={imageSelect(47)} className='single__seat' onClick={() => appendSeat(47)}>47</button>
                        </div>
                    </div>
                    <div className='legend ps-3'>
                        <div>Legend</div>
                        <div className='d-flex flex-column align-items-center'>
                            <button style={{
                                backgroundImage: `url(${reserved})`, backgroundSize: 'cover',
                                backgroundPosition: 'center', backgroundColor: 'white'
                            }} className='single__seat' ></button>
                            <div >Unavailable</div>
                        </div>
                        <div className='d-flex flex-column align-items-center'>
                            <button style={{
                                backgroundImage: `url(${selected})`, backgroundSize: 'cover',
                                backgroundPosition: 'center', backgroundColor: 'white'
                            }} className='single__seat' ></button>
                            <div className='text-center'>Current seat</div>
                        </div>
                        <div className='d-flex flex-column align-items-center'>
                            <button style={{
                                backgroundImage: `url(${open})`, backgroundSize: 'cover',
                                backgroundPosition: 'center', backgroundColor: 'white'
                            }} className='single__seat' ></button>
                            <div>Available</div>
                        </div>
                    </div>
                </div>
                <div className='details__trip__detail col-md-6'>
                    <div className='mt-5 p-!'>
                        <div className='fw-bold'>Trip Details</div>
                        <div>Zemen Bus Ethiopia</div>
                        <div className='row'>
                            <div className='col-4'>
                                <div>addis Ababa</div>
                                <div>04:00 AM</div>
                            </div>
                            <div className='col-4'>
                                <div className='text-center'>Details</div>
                                <div className='text-center'>515 Km</div>
                            </div>
                            <div className='col-4'>
                                <div className='text-end'>addis Ababa</div>
                                <div className='text-end'>04:00 AM</div>
                            </div>

                        </div>
                    </div>
                    <div className='payment__detail my-4 p-1'>
                        <div className='text-center py-1 fw-bold'>Payment Detail</div>
                        <div>
                            <div className='row'>
                                <div className='col-3'>Type</div>
                                <div className='col-2'>Price</div>
                                <div className='col-3 text-center'>Tickets</div>
                                <div className='col-4 text-end'>Total</div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>Student</div>
                                <div className='col-2'>1285</div>
                                <div className='col-3 text-center'>{tickets}</div>
                                <div className='col-4 text-end'>2458 ETB</div>
                            </div>
                            <div className='payment__detail__total row g-0'>
                                <div className='col-3 '>Total</div>
                                <div className='col-2'></div>
                                <div className='col-3'></div>
                                <div className='col-4 text-end'>2458 ETB</div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>

                        <Link to='/' className='detail__btn me-3 ' >Back</Link>
                        <Link to='/passengerDetail' className='detail__btn me-3 ' >Continue</Link>

                    </div>

                </div>








            </div>
            <div className='details__detail'></div>
        </div>
    )
}

export default Details