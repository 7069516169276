import React from 'react'
import { Link } from 'react-router-dom';

import PassangerDetail from './PassangerDetail.css'

function PassengerDetail() {
  return (
    <div className='passanger container'>
      <div className='passanger__title'>Passanger Detail</div>
      <div className='passanger__name d-md-flex'>
      <div className='w-100'>
        <div className='passanger__label'>First Name</div>
        <input className='passanger__input'></input>
      </div>
      <div className='w-100'>
        <div className='passanger__label'>Last Name</div>
        <input className='passanger__input'></input>
      </div>
      </div>
      <div>
        <div className='passanger__label'>Email</div>
        <input className='passanger__input'></input>
      </div>
      <div>
        <div className='passanger__label'>Phone</div>
        <div className='passanger__input'>
          <select className='passanger__input__phone__code'>
            <option>+251</option>
            <option>sfd</option>
            <option>sfd</option>
            <option>sfd</option>
            <option>sfd</option>
          </select>
        <input className='passanger__input__phone' ></input>
        </div>
      </div>
      <div>
        <Link>Proceed to Payment</Link>
      </div>
    </div>
  )
}

export default PassengerDetail