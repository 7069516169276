import React, { useState } from 'react'
import './Modal.css'
import { Modal, Button } from 'react-bootstrap'
function ModalContent({ content, button_text, }) {

  const [isShow, invokeModal] = useState(false)
  const initModal = () => {
  // console.log(isShow)

if(isShow==false){
  return invokeModal(!false)

}else{
    return invokeModal(false)

}
  }
  const closeModal = () => {
    // window.location.reload ()
    if(isShow==false){
      return invokeModal(!false)
    
    }else{
        return invokeModal(false)
    
    }
    // return invokeModal(false)
  }

  return (
    <div >
      <div className="init text-white "  >
        <Button variant="" className="create-btn text-black" onClick={initModal}>
          <div className='button__text'>{button_text}</div>
          
        </Button>
      </div>
      <Modal show={isShow}>
        {/* <Modal.Header closeButton onClick={initModal}>
                <Modal.Title>React Modal Popover Example</Modal.Title>
              </Modal.Header> */}
        <Modal.Body className="modalbox ">

          <div className="text-center">
            {content}
          </div>
          {/* <Button variant="danger" onClick={closeModal}>
                  Close
                </Button> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant="danger" onClick={closeModal}>
                  Close
                </Button>
                
              </Modal.Footer> */}
      </Modal>
    </div>

  )
}
export default ModalContent