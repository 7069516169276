export const initialState = {
    
    basket: [],
    loading: false,
    user: null,
    tickets:1
  };
  
  
  const reducer = (state, action) => {
    // console.log(action);
    
    switch (action.type) {
      case 'SET_TICKETS':
        return {
          ...state,
          tickets: action.tickets,
        };
      case 'SET_USER':
        return {
          ...state,
          user: action.user,
        };
        case 'SET_BASKET':
        return {
          ...state,
          basket: [...state.basket, action.item],
        };
        case 'SET_LOADING':
        return {
          ...state,
          loading:action.load,
        };
  
      default:
        return state;
    }
  };
  
  export default reducer;
  