import React from "react";
import "./Sign.css";

import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import { useState } from "react";
import ForgotPassword from "./ForgotPassword";

function Sign() {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  return (
    <div className="sign">
      <div className="sign__left">
        <div className="sign__left__head">Wellcome Back To Zemen</div>
      </div>
      <div
        
        className="sign__right"
      >
        <div className={isActive ? "" : "display"}
        // className="signin"
        >
          <SignUp />
        </div>
        <div
          className={isActive ? "display signIn" : ""}
          // className="signup"
        >
          
          <SignIn />
        </div>
        <ForgotPassword/>

        
        <button  className={isActive ? "display switcher" : "switcher "} onClick={handleClick}>Not Registered Yet? Create new account </button>
        <button className={isActive ? "signIn switcher" : "display switcher"}onClick={handleClick}>Already have an account? </button>
        
        
        
      </div>
      
      
    </div>
  );
}

export default Sign;
